import shopifyGenericDefaults from '../shopify-generic/config.js';

globalThis.Convermax.storePhoneNumber = window.document.querySelector('footer a[href^="tel:"]')?.outerHTML;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => w.name !== 'SearchBox'),
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '#cm-search-box', class: 'cm-search-box cm_mobile-hide' },
      template: 'searchBox/dialogButtonAdaptable',
    },
  ],
};
